<template>
    <div class="db-container dashboard-pro-overview">
        <div class="db-content">
            <h2 class="title">
                {{$t('header.dashboard')}}
            </h2>
            <div class="controller">
                <InputUnit title="" type="select" :selectOptions="standardOptions" v-model="standard"/>
                <Switcher :options="periodOptions" v-model="period" />
            </div>
            <div class="content">
                <div v-if="!overviewLoading" :class="`medium static ${hasAdmin ? '' : 'not-admin'}`">
                    <img class="icon" src="@/assets/images/dashboard-pro/total-equitiy-value.svg" />
                    <h6>Total Equity Value</h6>
                    <h2>${{ this.overview.equity / this.standardRate | currency }}</h2>
                </div>
                <div v-if="!overviewLoading" :class="`medium static ${hasAdmin ? '' : 'not-admin'}`">
                    <img class="icon" src="@/assets/images/dashboard-pro/pnl-since-inception.svg" />
                    <h6>Total PnL Since Inception</h6>
                    <h2>${{ this.overview.pnl / this.standardRate | currency }}</h2>
                </div>
                <div v-if="!portfoliosLoading" :class="`medium static ${hasAdmin ? '' : 'not-admin last'}`">
                    <img class="icon" src="@/assets/images/dashboard-pro/portfolios.svg" />
                    <h6>Portfolios</h6>
                    <h2>{{ this.overview.operating }}</h2>
                </div>
                <div v-if="!portfoliosLoading && hasAdmin" class="medium static">
                    <img class="icon" src="@/assets/images/dashboard-pro/invertor.svg" />
                    <h6>Investors</h6>
                    <h2>{{ this.portfolios.users.length }}</h2>
                </div>
                <div v-if="!overviewLoading" class="large chart">
                    <h6>Equity Value Stats</h6>
                    <div>
                        <DashboardEquitiyValue :records="equityData" :height="250" :styles="myStyles"/>
                    </div>
                </div>
                <div v-if="!overviewLoading" class="small vertical-chart">
                    <h6>Trading Volume Stats</h6>
                    <h3>${{ volumeData.reduce((acc, cur) => acc + cur.total, 0) / this.standardRate | currency }}</h3>
                    <p>Total Trading Volume</p>
                    <div class="chart">
                        <div :key="ind" v-for="(ele, ind) in volumeData">
                            <div class="first" :style="`height: ${ele.spot * 100}%;`"></div>
                            <div class="second" :style="`height: ${ele.contract * 100}%;`"></div>
                        </div>
                    </div>
                    <div class="description">
                        <p>Spot</p>
                        <p>Contract</p>
                    </div>
                </div>
                <div v-if="false" class="medium trend decrease">
                    <h6>7-Day PNL</h6>
                    <h2>-1.23%</h2>
                    <p>-$123.12</p>
                </div>
                <div v-if="false" class="medium trend increase">
                    <h6>30-Day PNL</h6>
                    <h2>4.12%</h2>
                    <p>+$1233.12</p>
                </div>
                <div v-if="false" class="medium trend increase">
                    <h6>90-Day PNL</h6>
                    <h2>12.45%</h2>
                    <p>$12K</p>
                </div>
                <div v-if="false" class="medium trend increase">
                    <h6>Since Inception</h6>
                    <h2>22.45%</h2>
                    <p>$12k</p>
                </div>
                <div v-if="!overviewLoading" class="large bar">
                    <h6>PNL Stats</h6>
                    <div class="chart-container">
                        <PNLStats :records="pnlData" :styles="myStyles"/>
                    </div>
                </div>
                <!-- <div class="small small-chart">
                    <h6>APY Stats</h6>
                    <h3>{{ apyData.length != 0 ? apyData.slice(-1)[0].data : 0 | rate(2) }}%</h3>
                    <p>APY Since Inception</p>
                    <div class="chart-container" style="margin: 0px 0px 0px -8px;">
                        <APYStats :records="apyData" :styles="myStyles"/>
                    </div>
                </div> -->
                <div v-if="!portfoliosLoading" class="small equity-allocation">
                    <h6>Equity Allocation</h6>
                    <div class="port"  :key="ind" v-for="(ele, ind) in portfoliosListSortByEquity">
                        <p>{{ ele.product_name }}</p><span>{{ (ele.equity / totalEquity * 100).toFixed(2) }}%</span>
                        <div class="progress-bg">
                            <div class="progress-content" :style="`width: ${(ele.equity / totalEquity * 100).toFixed(2)}%;`"></div>
                        </div>
                    </div>
                </div>
                <div v-if="!portfoliosLoading" class="large portfolios-table">
                    <h6>Top Performance Portfolios<a href="/dashboard-pro/portfolios">View All</a></h6>
                    <div class="table-container">
                        <table>
                            <tr>
                                <th>Portfolio Name</th>
                                <th>Standard</th>
                                <th>Net Value</th>
                                <th>Equity Value</th>
                                <th>APY</th>
                                <th>PnL%</th>
                            </tr>
                            <tr :key="ind" v-for="(ele, ind) in portfoliosListSortByAPY.slice(0, 5)">
                                <td><h5>{{ ele.product_name }}</h5><h6>{{ ele.portfolio_series }}</h6></td>
                                <td>{{ ele.standard.toUpperCase() }}</td>
                                <td>{{ Number(ele.nav).toFixed(4) }}</td>
                                <td>{{ ele.equity | currency(1) }}</td>
                                <td>{{ ele.roi_y | rate(2) }}%</td>
                                <td>{{ Number(ele.nav) - 1 | rate(2) }}%</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import $ from 'jquery';
import InputUnit from '@/components/InputUnit';
import Switcher from '@/components/Switcher';
import DashboardEquitiyValue from '@/components/DashboardEquitiyValue';
import {apiOverview} from '@/apis/pro/overview.js';
import {apiPortfoliosList} from '@/apis/pro/portfolios.js';
import PNLStats from '@/components/PNLStats';
// import APYStats from '@/components/APYStats';
import Cookies from 'js-cookie';
// import {apiNewsDetail} from '@/apis/news.js';

export default {
    name: 'Overview',
    beforeCreate: function() {
        document.body.className = 'noscroll';
    },
    data() {
        return {
            overviewLoading: true,
            portfoliosLoading: true,
            standardOptions: [{
                title: 'USDT-Margined',
                value: 'usdt',
                icon: this.loadIcon('USDT')
            }, {
                title: 'BTC-Margined',
                value: 'btc',
                icon: this.loadIcon('BTC')
            }, {
                title: 'ETH-Margined',
                value: 'eth',
                icon: this.loadIcon('ETH')
            }],
            periodOptions: [{
                title: '7 Days',
                value: '7'
            }, {
                title: '30 Days',
                value: '30'
            }, {
                title: '90 Days',
                value: '90'
            }],
            period: '7',
            standard: 'usdt',
            chartData: [
                {label: new Date('2022-05-12'), data: -5000},
                {label: new Date('2022-05-13'), data: 14000},
                {label: new Date('2022-05-14'), data: 8000},
                {label: new Date('2022-05-15'), data: 25000},
                {label: new Date('2022-05-16'), data: 19000},
                {label: new Date('2022-05-17'), data: 40000},
                {label: new Date('2022-05-18'), data: 30000},
            ],
            overview: {},
            portfolios: [],
        }
    },
    components: {
        InputUnit,
        Switcher,
        DashboardEquitiyValue,
        PNLStats,
        // APYStats,
    },
    methods: {
        doughnutOnHover(e, item){
            if (item.length) {
                console.log(item[0]._index);
            }
        },
        loadOverview(){
            apiOverview({
                start: 0
            }).then(res => {
                if(res.data.result){
                    this.overview = res.data
                    this.overviewLoading = false
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        loadPortfolios(){
            apiPortfoliosList().then(res => {
                if(res.data.result){
                    this.portfolios = res.data
                    this.portfoliosLoading = false
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        loadIcon(name = ""){
            if(name == "GBP")return `https://static.bitfinex.com/images/icons/GBP-alt.svg`;
            try {
                return require(`@/assets/images/crypto-logos/${name.replace('-PERP', '')}.png`);
            } catch (e) {
                // return require(`@/assets/images/crypto-default.png`);
                return `https://static.bitfinex.com/images/icons/${name.replace('-PERP', '')}-alt.svg`;
            }
        },
    },
    mounted() {
        this.loadOverview()
        this.loadPortfolios()
    },
    computed: {
        myStyles() {
          return {
            height: '100%',
            position: 'relative',
          };
        },
        equityData() {
            let sl = (this.period) * -1 -1
            if(!Array.isArray(this.overview.data))return [];
            else if(this.overview.data.length == 0)return [];
            return this.overview.data.map(item => ({label: new Date(item.ts*1e3).toISOString().split('T')[0], data: item.equity / this.standardRate})).slice(sl)
        },
        // apyData() {
        //     let sl = this.period * -1
        //     if(!Array.isArray(this.overview.data))return [];
        //     else if(this.overview.data.length == 0)return [];
        //     return this.overview.data.map(item => ({label: new Date(item.date.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3")), data: item.roi_y})).slice(sl)
        // },
        volumeData() {
            if(!Array.isArray(this.overview.data))return [];
            else if(this.overview.data.length == 0)return [];
            if(this.period == 7){
                let data = this.overview.data.map(item => Object.assign({}, item.volume)).slice(-8)
                const max = data.reduce((pre, now) => pre > (now.contract + now.spot) ? pre : (now.contract + now.spot), 0)
                data = data.map(item => ({
                    contract: item.contract / max,
                    spot: item.spot / max,
                    total: item.contract + item.spot
                }))
                return data
            }else if(this.period == 30){
                let data = this.overview.data.map(item => Object.assign({}, item.volume)).slice(-31)
                data = data.reduce((acc, cur, ind) => {
                    if(ind % 5 ==0){
                        acc.push(cur)
                    }else{
                        acc[Math.floor(ind/5)].contract += cur.contract
                        acc[Math.floor(ind/5)].spot += cur.spot
                    }
                    return acc
                }, [])
                const max = data.reduce((pre, now) => pre > (now.contract + now.spot) ? pre : (now.contract + now.spot), 0)
                data = data.map(item => ({
                    contract: item.contract / max,
                    spot: item.spot / max,
                    total: item.contract + item.spot
                }))
                return data
            }else if(this.period == 90){
                let data = this.overview.data.map(item => Object.assign({}, item.volume)).slice(-91)
                data = data.reduce((acc, cur, ind) => {
                    if(ind % 15 ==0){
                        acc.push(cur)
                    }else{
                        acc[acc.length - 1].contract += cur.contract
                        acc[acc.length - 1].spot += cur.spot
                    }
                    return acc
                }, [])
                const max = data.reduce((pre, now) => pre > (now.contract + now.spot) ? pre : (now.contract + now.spot), 0)
                data = data.map(item => ({
                    contract: item.contract / max,
                    spot: item.spot / max,
                    total: item.contract + item.spot
                }))
                return data
            }
            return []
        },
        pnlData() {
            if(!Array.isArray(this.overview.data))return [];
            else if(this.overview.data.length == 0)return [];
            let maxLength = {
                30: Math.min(this.overview.data.length, 31),
                90: Math.min(this.overview.data.length, 91)
            }
            const data = this.overview.data.map(item => ({label: new Date(item.ts*1e3).toISOString().split('T')[0], data: item.pnl / this.standardRate}))
            if(this.period == 7){
                return data.slice(-7)
            }else if(this.period == 30){
                return data.slice(-30).filter((item, ind) => (this.overview.data.length - ind - 1) % (Math.ceil(maxLength[30]/10)) == 0 )
            }else{
                return data.slice(-90).filter((item, ind) => (this.overview.data.length - ind - 1) % (Math.ceil(maxLength[90]/10)) == 0 )
            }
        },
        totalEquity(){
            return this.portfolios.products.filter(ele => ele.active == 1).reduce((total, ele) => total + ele.equity, 0)
        },
        portfoliosListSortByEquity(){
            let tmp = this.portfolios.products.slice().filter(ele => ele.active == 1).map(ele => ({product_name: ele.product_name, equity: ele.equity}))
            tmp.sort(function(a, b){
                if(a.equity > b.equity){
                    return -1
                }else if(b.equity > a.equity){
                    return 1
                }
                return 0
            })
            if(tmp.length > 5){
                let tmp2 = tmp.slice(0, 4)
                tmp2.push({
                    product_name: 'Others',
                    equity: tmp.reduce((acc, cur, ind) => acc + (ind >= 4 ? cur.equity : 0), 0)
                })
                tmp = tmp2
                console.log(tmp2)
            }
            return tmp
        },
        portfoliosListSortByAPY(){
            let tmp = this.portfolios.products.slice().filter(ele => ele.active == 1)
            return tmp.sort(function(a, b){
                if(a.roi_y > b.roi_y){
                    return -1
                }else if(b.roi_y > a.roi_y){
                    return 1
                }
                return 0
            })
        },
        standardRate(){
            if(this.standard == 'usdt'){
                return 1
            }else if(this.standard == 'btc'){
                return this.overview.data.slice(-1)[0].btc_price
            }else if(this.standard == 'eth'){
                return this.overview.data.slice(-1)[0].eth_price
            }
            return 1
        },
        hasAdmin(){
            return Cookies.get('dcbotLogin_role') == 'admin'
        }
    },
}
</script>

<script>
import { Bar } from 'vue-chartjs'
import moment from 'moment'

export default {
    extends: Bar,
    data() {
        return{
            options: {
                maintainAspectRatio: false,
                scaleShowVerticalLines: false,
                responsive: true,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            maxTicksLimit: 7,
                            userCallback: function(num) {
                                if(Math.abs(num) >= 1000000000){
                                    return `${Math.round(num / 1000000000 * 100) / 100}B`;
                                }else if(Math.abs(num) >= 1000000){
                                    return `${Math.round(num / 1000000 * 100) / 100}M`;
                                }else if(Math.abs(num) >= 1000){
                                    return `${Math.round(num / 1000 * 100) / 100}K`;
                                }
                                return Math.round(num * 100) / 100;
                            },
                            padding: 20,
                            beginAtZero: false
                        },
                        gridLines: {
                            drawOnChartArea: false,
                            display: false,
                            color: '#F4F3F6',
                            zeroLineColor: '#F4F3F6'
                        },
                    }],
                    xAxes: [{
                        ticks: {
                            maxTicksLimit: 10,
                            padding: 20
                        },
                        gridLines: {
                            drawOnChartArea: false,
                            display: false,
                            color: '#F4F3F6',
                            zeroLineColor: '#F4F3F6'
                        },
                    }]
                },
                tooltips: {
                    enabled: false,
                    custom: function(tooltipModel) {
                        // Tooltip Element
                        var tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            var bodyLines = tooltipModel.body.map(getBody);
                            var titleLines = tooltipModel.title || [];
                            var innerHtml = '<tbody>';

                            bodyLines.forEach(function(body) {
                                innerHtml += '<tr><td style="color: ' + (parseInt(body) > 0 ? '#3AAA96' : '#C31F25') + ';font-size: 14px;text-align: center;">$' + Number(body).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,').slice(0, -2) + '</td></tr>';
                                innerHtml += '<tr><td style="color: ' + (parseInt(body) > 0 ? '#3AAA96' : '#C31F25') + ';text-align: center;">' + titleLines + '</td></tr>';
                                innerHtml += `<tr><td><img src="${require(parseInt(body) > 0 ? '@/assets/images/dashboard-pro/bartip-pos.svg' : '@/assets/images/dashboard-pro/bartip-neg.svg')}" style="position: absolute;bottom: -6px;left: 50%;transform: translate(-50%, 0);" /></td></tr>`;
                                tooltipEl.style.backgroundColor = parseInt(body) > 0 ? '#E8FEFA' : '#FFEDEE'
                            });
                            innerHtml += '</tbody>';

                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.borderRadius = '4px'
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth / 2) + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 3 + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + 16 + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                }
            },
        }
    },
    props: {
        records: {
            type: Array,
            default: () => []
        }
    },
    mounted () {
        this.renderChart(this.chartData, this.options)
    },
    watch: {
        records: function(){
            this.renderChart(this.chartData, this.options)
        }
    },
    computed:{
        chartData(){
            return ({
                labels: this.records.map(ele => moment(ele.label).format('DD MMM, YY')),
                datasets: [{
                    label: '',
                    data: this.records.map(ele => ele.data),
                    backgroundColor: this.records.map(ele => ele.data > 0 ? '#84DCCC' : '#FF545A'),
                    hoverBackgroundColor: this.records.map(ele => ele.data > 0 ? '#3AAA96' : '#C31F25'),
                    barThickness: 36
                }]
            })
        }
    }
}
</script>
